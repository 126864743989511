@import url("https://fonts.cdnfonts.com/css/avenir");
@import url("https://fonts.cdnfonts.com/css/circular-std");
@import url("https://fonts.cdnfonts.com/css/autografia-personal-use-only");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Define base color variables */
    --light-color: #ffffff;
    --dark-color: #000000;

    /* Generate shades of the primary color */
    --color-pade-black: #0c1b3d;
    --color-pade-yellow: #fcbf23;
    --color-pade-subText: #6c7a93;
    --color-pade-light-blue: #ccdbfd;
    --color-primary50: color-mix(
      in srgb,
      rgba(var(--color-primary)) 10%,
      var(--light-color)
    );
    --color-primary100: color-mix(
      in srgb,
      rgba(var(--color-primary)) 20%,
      var(--light-color)
    );
    --color-primary200: color-mix(
      in srgb,
      rgba(var(--color-primary)) 30%,
      var(--light-color)
    );
    --color-primary300: color-mix(
      in srgb,
      rgba(var(--color-primary)) 40%,
      var(--light-color)
    );
    --color-primary400: color-mix(
      in srgb,
      rgba(var(--color-primary)) 50%,
      var(--light-color)
    );
    --color-primary500: rgb(var(--color-primary));
    --color-primary600: color-mix(
      in srgb,
      rgba(var(--color-primary)) 70%,
      var(--dark-color)
    );
    --color-primary700: color-mix(
      in srgb,
      rgba(var(--color-primary)) 80%,
      var(--dark-color)
    );
    --color-primary800: color-mix(
      in srgb,
      rgba(var(--color-primary)) 90%,
      var(--dark-color)
    );
    --color-primary900: color-mix(
      in srgb,
      rgba(var(--color-primary)) 100%,
      var(--dark-color)
    );
  }

  .btn-active {
    @apply bg-primary500 px-[10px] h-[30px] rounded-[5px] text-sm text-white;
  }
  .btn-inactive {
    @apply bg-grey10 px-[10px] h-[30px]  text-sm  text-neutral500;
  }
}

* {
  margin: 0;
  font-family: "Avenir", "Circular Std", sans-serif;
}
body {
  margin: 0;
  font-family: "Avenir", "Circular Std", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #root {
  isolation: isolate;
  height: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Circular Std", sans-serif;
}

p,
span,
div,
li,
td,
th {
  font-family: "Avenir", "Circular Std", sans-serif;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbcr-track {
  background-color: theme("colors.transparent");
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: theme("colors.neutral50");
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: theme("colors.neutral60");
}
select::-ms-expand {
  display: none;
}

.custom {
  content: "";
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* remove x button in search */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

/* remove input buttons */
input::-webkit-inner-spin-button {
  display: none;
}

.white-svg > path {
  stroke: #fff;
}
.primary-svg > path {
  stroke: theme("colors.primary400");
}

/* Toggle Switch Input */
.toggle-switch-checkbox:checked + .toggle-switch-label::before {
  transform: translateX(1.75rem);
  background-color: var(--color-primary);
  right: 0;
}

.toggle-switch-checkbox:focus + .toggle-switch-label::before {
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

.toggle-switch-checkbox:checked:focus + .toggle-switch-label::before {
  box-shadow: 0 0 0 2px rgba(72, 187, 120, 0.5);
}

.toggle-switch-checkbox:focus-visible + .toggle-switch-label::before {
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5),
    0 0 0 4px rgba(66, 153, 225, 0.3);
}

/* Toggle Switch Label */
.toggle-switch-label {
  margin-left: 1rem;
  cursor: pointer;
}

.toggle-switch-label::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 9999px;
  background-color: #b6cffc;
  transition: transform 0.2s ease, background-color 0.2s ease,
    box-shadow 0.2s ease;
}

/* Toggle Switch Container */
.toggle-switch-container {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  position: relative;
  border: 1px solid #b6cffc;
  border-radius: 20px;
  width: 2.7rem;
  height: 1rem;
}

/* Toggle Switch Checkbox */
.toggle-switch-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.ant-table-filter-dropdown-btns > .ant-btn-primary {
  background-color: rgb(var(--color-primary));
}

/* Updated  Loader */
.loader {
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  color: #243757;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em #243757,
      2.5em 0em 0 0em #243757, 1.75em 1.75em 0 0em #243757,
      0em 2.5em 0 0em #243757, -1.8em 1.8em 0 0em #243757,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em #243757,
      1.75em 1.75em 0 0em #243757, 0em 2.5em 0 0em #243757,
      -1.8em 1.8em 0 0em #243757, -2.6em 0em 0 0em #243757,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em #243757, 0em 2.5em 0 0em #243757,
      -1.8em 1.8em 0 0em #243757, -2.6em 0em 0 0em #243757,
      -1.8em -1.8em 0 0em #243757;
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em #243757,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em #243757, -1.8em 1.8em 0 0em #243757,
      -2.6em 0em 0 0em #243757, -1.8em -1.8em 0 0em #243757;
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em #243757, 1.8em -1.8em 0 0em #243757,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em #243757, -2.6em 0em 0 0em #243757,
      -1.8em -1.8em 0 0em #243757;
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em #243757, 1.8em -1.8em 0 0em #243757,
      2.5em 0em 0 0em #243757, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em #243757, -1.8em -1.8em 0 0em #243757;
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em #243757, 1.8em -1.8em 0 0em #243757,
      2.5em 0em 0 0em #243757, 1.75em 1.75em 0 0em #243757,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em #243757;
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em #243757, 1.8em -1.8em 0 0em #243757,
      2.5em 0em 0 0em #243757, 1.75em 1.75em 0 0em #243757,
      0em 2.5em 0 0em #243757, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.loaderwhite {
  font-size: 3px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpinWhite 1.1s infinite ease;
  color: #243757;
  transform: translateZ(0);
}
@keyframes mulShdSpinWhite {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em var(--color-primary),
      2.5em 0em 0 0em var(--color-primary),
      1.75em 1.75em 0 0em var(--color-primary),
      0em 2.5em 0 0em var(--color-primary),
      -1.8em 1.8em 0 0em var(--color-primary),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em var(--color-primary),
      1.75em 1.75em 0 0em var(--color-primary),
      0em 2.5em 0 0em var(--color-primary),
      -1.8em 1.8em 0 0em var(--color-primary),
      -2.6em 0em 0 0em var(--color-primary),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em var(--color-primary),
      0em 2.5em 0 0em var(--color-primary),
      -1.8em 1.8em 0 0em var(--color-primary),
      -2.6em 0em 0 0em var(--color-primary),
      -1.8em -1.8em 0 0em var(--color-primary);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em var(--color-primary),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em var(--color-primary),
      -1.8em 1.8em 0 0em var(--color-primary),
      -2.6em 0em 0 0em var(--color-primary),
      -1.8em -1.8em 0 0em var(--color-primary);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em var(--color-primary),
      1.8em -1.8em 0 0em var(--color-primary),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em var(--color-primary),
      -2.6em 0em 0 0em var(--color-primary),
      -1.8em -1.8em 0 0em var(--color-primary);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em var(--color-primary),
      1.8em -1.8em 0 0em var(--color-primary),
      2.5em 0em 0 0em var(--color-primary),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em var(--color-primary),
      -1.8em -1.8em 0 0em var(--color-primary);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em var(--color-primary),
      1.8em -1.8em 0 0em var(--color-primary),
      2.5em 0em 0 0em var(--color-primary),
      1.75em 1.75em 0 0em var(--color-primary),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em var(--color-primary);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em var(--color-primary),
      1.8em -1.8em 0 0em var(--color-primary),
      2.5em 0em 0 0em var(--color-primary),
      1.75em 1.75em 0 0em var(--color-primary),
      0em 2.5em 0 0em var(--color-primary),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* CALENDAR */
/* .btn {
  border-radius: 25px;
  border-color: #ddd;
}

.btn:hover {
  border: solid 1px #bbb;
  background-color: #fff;
}

.btn:active {
  background-color: #f9f9f9;
  border: solid 1px #bbb;
  outline: none;
}

.btn:disabled {
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  color: #bbb;
}

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none;
}

.move-today {
  padding: 0 16px;
  line-height: 30px;
}

.move-day {
  padding: 8px;
}

.render-range {
  padding-left: 12px;
  font-size: 19px;
  vertical-align: middle;
} */

/** custom fontawesome */
.fa {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.horizontal-scrollbar::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.horizontal-scrollbar::-webkit-scrollbcr-track {
  background-color: theme("colors.transparent");
}

/* Handle */
.horizontal-scrollbar::-webkit-scrollbar-thumb {
  background-color: theme("colors.neutral50");
  border-radius: 10px;
}

/* Handle on hover */
.horizontal-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: theme("colors.primary400");
  cursor: pointer;
}

img,
video {
  display: inline;
}

/* QUILL */
.ql-align-center {
  text-align: center;
}
.quill {
  min-height: 300px !important;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-snow .ql-editor img {
  /* max-width: 100%; */
  display: inline;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  font-family: "Avenir", "Circular Std", sans-serif !important;
}

.ql-picker.ql-insertCustomTags {
  width: 90px;
}

.ql-picker.ql-dropdown {
  width: 90px;
}

.ql-picker-options {
  margin-top: 4px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}

.ql-insertCustomTags .ql-picker-item:before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-insertCustomTags {
  height: fit-content !important;
  width: fit-content !important;
}
.ql-snow .ql-picker.ql-insertCustomTags .ql-picker-label {
  padding-inline-end: 20px;
  padding-block: 4px;
  border: 1px solid #c2c7d0;
  border-radius: 4px;
}

.ql-insertCustomTags1 .ql-picker-item:before {
  content: attr(data-label);
}

.ql-myDropdown {
  z-index: 100;
}

.quill .ql-toolbar.ql-snow {
  background: #f0f3f7;
  height: 60px !important;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* border: none !important; */
  /* /* z-index: 500;  */
}
/* .ql-formats{
  border:  red solid 2px;
  width: 30px !important;

} */

.ql-container.ql-snow {
  border: none !important;
}
.ql-editor {
  outline: none;
  border: none !important;
}

/* Antd */
/* Ant input font-size */
.ant-select-selection-search > input {
  @apply !text-neutral400 !text-base md:!text-sm;
}
.ant-picker-input > input {
  @apply !text-neutral400 !text-base md:!text-sm;
}

.employee__layout .ant-table-wrapper * {
  @apply !font-avenir;
}
.employee__layout .ant-table-wrapper th {
  @apply !text-neutral500;
}
.admin__layout .ant-table-wrapper * {
  @apply !font-avenir;
}
.admin__layout .ant-table-wrapper th {
  @apply !text-neutral500;
}

/* Antd */
/* .ant-steps-item-container {

} */
.ant-steps
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content {
  margin-top: 4px;
}

.ant-steps
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #243757;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.ant-steps-item .ant-steps-item-tail {
  padding-block: 0 !important;
  padding-inline-start: 10px !important;
  padding-inline-end: 20px !important;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-icon svg {
  fill: #ebfaf3;
  stroke-width: 100;
  stroke: #ebfaf3;
}

.ant-steps-item.ant-steps-item-wait
  .ant-steps-item-container
  .ant-steps-item-icon {
  border: 1px solid var(--color-primary);
  background-color: #fff;
}
.ant-steps-item.ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-icon {
  background-color: #38cb89;
  color: #fff;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #38cb89;
}
.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #c2c7d0;
}
.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #c2c7d0;
}

.ant-steps
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  height: 2px;
}

/* Table Column Headers */
.ant-table-thead > tr > th {
  overflow-wrap: normal !important;
  /* word-wrap: normal !important; */
}

.ant-table-content {
  scrollbar-width: thin;
  scrollbar-color: unset;
}

/* SELECT COMPONENT STYLES */

.ant-select.pade-select {
  border: 1px solid #c2c7d0;
  background-color: transparent !important;
  border-radius: 8px;
  margin: 8px 0 10px 0;
}

.ant-select-arrow-loading {
  color: #1e293b !important;
}

.ant-select.pade-select .ant-select-selector {
  box-shadow: none;
  background-color: #f1f5f9;
  border: none;
  color: #1e293b;
  border-radius: 8px;
  margin: 10px 0;
}

.ant-select.pade-select .ant-select-selector .ant-select-selection-item {
  line-height: 45px;
  color: #1e293b !important;
  height: auto;
}

.ant-select.pade-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px;
  color: #1e293b !important;
  opacity: 0.7;
}

.ant-select-item.ant-select-item-option {
  margin: 4px 5px;
  color: #1e293b !important;
}

textarea {
  resize: none;
}
/* PROGRESS BAR */
.emp_progress .ant-progress-inner {
  background-color: white !important;
}
.emp_progress .ant-progress-text {
  color: white !important;
}
.emp_progress .ant-progress-outer {
  width: 95% !important;
}
.emp_progress .ant-progress-bg {
  background-color: gray !important ;
}
/* CUSTOM TABLE */
.custom-table .ant-table-tbody > tr > td {
  padding: 6px 8px; /* Adjust the padding values as needed */
}
/* DATE PICKER */
.periodDate .ant-picker {
  display: flex !important;
}
/**/
/* sideNavigationBar scrollbar */

.custom__sidebar__scrollbar {
  @apply h-screen;
}

.custom__sidebar__scrollbar::-webkit-scrollbar {
  @apply w-[8px];
}

.custom__sidebar__scrollbar::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.custom__sidebar__scrollbar::-webkit-scrollbar-thumb {
  @apply bg-[#15294b] bg-opacity-70 hover:bg-opacity-70 rounded-[4px] md:rounded-sm;
}

:where(.css-dev-only-do-not-override-1rxdlzw).ant-btn-primary {
  @apply bg-primary500 text-white;
}

/* Accordion */
.ant-collapse-header {
  align-items: center !important;
}
.ant-collapse-expand-icon {
  height: 100% !important;
  margin: 0 !important;
}
.ant-collapse-item:last-child {
  border-bottom: 2px solid #6c7a9326 !important;
  border-radius: 5px;
}
@media (max-width: 640px) {
  .perfCollapse .ant-collapse-expand-icon {
    display: none !important;
  }
  .perfCollapse .ant-collapse-header {
    padding: 8px !important;
  }
}

/* Tour */
.ant-tour .ant-tour-inner {
  background-color: #15294b !important;
  color: #ffffff !important;
}
.ant-tour .ant-tour-prev-btn {
  background-color: white !important;
  font-weight: 500;
}
.ant-tour {
  width: 413px !important;
}
.ant-tour .ant-tour-inner .ant-tour-close {
  color: white !important;
}
.ant-tour .ant-tour-inner .ant-tour-footer {
  margin-top: 20px;
}
.ant-tour .ant-tour-title {
  font-size: 16px !important;
}
.ant-tour .ant-tour-indicator {
  background: white !important;
}
.ant-tour .ant-tour-indicator-active {
  background: #1464f4 !important;
}
.ant-tour .ant-tour-next-btn {
  background: #1464f4;
}
/* ALERT */
.invoice-alert .ant-alert-message {
  font-weight: 800 !important;
}
.invoice-alert .ant-alert-description {
  font-size: 14px !important;
  color: #0c1b3d !important;
}
.invoice-alert .ant-alert-message {
  font-size: 16px !important;
}
.invoice-alert .ant-alert {
  border-radius: 0px !important;
}
.invoice-alert {
  padding: 10px 24px !important;
}
@media (max-width: 1024px) {
  .invoice-alert .ant-alert-message {
    font-size: 13px !important;
  }
  .invoice-alert .ant-alert-description {
    font-size: 12px !important;
  }
  .invoice-alert {
    padding: 10px 10px 40px 10px !important;
  }
}
.card-cover-animation {
  top: -35%;
  z-index: 0;
  width: 100%;
  transition: all 2s all;
  animation: slideCover 2s forwards;
  right: 0;
  left: 0;
}

@keyframes slideCover {
  0% {
    top: -35%;
    right: 0;
    left: 0;
    width: 100%;
  }
  40% {
    top: -110%;
  }
  100% {
    top: -35%;
    order: 0;
    z-index: 20;
  }
}
/* WIthdrawal */
.withdrawal .ant-form-item-required {
  width: 100%;
}
